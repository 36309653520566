.App-content {
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
}

.App {
  background-color: #1f1f1f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 12px;
}

h1 {
  font-family: "Righteous", sans-serif;
  font-size: min(8rem, calc((100vw - 4.2rem) / 2.8));
  margin: 0;
  letter-spacing: 0.1em;
  padding-left: 16px;
  user-select: none;
  margin-top: -100px;
}

.spinner {
  font-family: "Righteous", sans-serif;
  font-size: min(8rem, calc((100vw - 4.2rem) / 2.8));
  display: inline-block;
  animation: App-logo-spin infinite 2s linear;
  transform-origin: 51.35% 51.165%;
  margin: 0;
  letter-spacing: 0.1em;
  padding-left: 16px;
  user-select: none;
}

h2 {
  font-size: min(1.8rem, calc((100vw - 1rem) / 14));
  font-weight: 300;
  margin: 0;
  margin-top: -32px;
  margin-bottom: 40px;
  user-select: none;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: right;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
}
footer a > * {
  opacity: 0.5;
  transition: opacity 0.5s;
  /* margin-right: 20px; */
}
footer a:hover > * {
  opacity: 1;
}
footer a {
  transition: transform 0.5s;
  display: inline-block;
}
footer a:hover {
  transform: translateY(-12px);
}

.App-logo {
  pointer-events: none;
  display: inline-block;
}

@media (prefers-reduced-motion: no-preference) {
  h1:hover .App-logo {
    animation: App-logo-spin 1s ease-in-out;
    animation-direction: alternate;
    transform-origin: 44.2% 51.25%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1440deg);
  }
}
